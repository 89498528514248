import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BaseCard from '../../components/baseCard';
import { TextField, MenuItem, makeStyles, Grid, FormControl, FormLabel, FormGroup, FormControlLabel, Checkbox, Slider, IconButton, Button, Hidden } from '@material-ui/core';
import { InfoRounded as Info, Save, Restore } from '@material-ui/icons';
import MuiAccordion from '@material-ui/core/Accordion';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FantasyButton from '../../components/fatnasyButton';
import PropTypes from 'prop-types';
import Tooltip from "../../components/tooltip";
import RandomizerDialog from '../../components/RandomizerDialog/randomizerDIalog';
import { SettingsInputComponentOutlined } from '@material-ui/icons';
import CustomStackDialog from '../../components/CustomStackDialog/index.js';
import OwnershipSettingsDialog from '../../components/OwnershipSettingsDialog/index.js';
import FlexExposureDialog from '../../components/FlexExposureDialog/FlexExposureDialog';
import MembershipTierDialog from '../../components/membershipTierDialog/membershipTierDialog'
import usePermissions from '../../hooks/usePermissions';
import SaveConfigDialog from '../../components/saveConfigDialog/saveConfigDialog.js';
import LoadConfigDialog from '../../components/saveConfigDialog/loadConfigDialog.js';
import { ColorButton } from '../advanceSettings/index.jsx';

const ADVANCED_FEATURES = {
    enableCustomStacks: 'Custom Stacks',
    randomize: 'Randomize Projections',
    enableFlexExposure: 'Max Flex Exposure',
    enableOwnershipSettings: 'Ownership Settings',
    enablePosExposure: 'Max Exposure By Position'
}

const Accordion = withStyles({
    root: {
        border: 'unset',
        boxShadow: 'none',
        width: "100%",
        margin: "2.5px 12px",
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: "2.5px 12px",
        },

    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        padding: -1,
        minHeight: 36,
        '&$expanded': {
            minHeight: 36,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles({
    root: {
        margin: "1rem",
    },
    checkbox: {
        padding: '6px'
    },
    checkboxLabel: {
        fontSize: '0.75rem'
    },
    accordionTitle: {
        fontSize: '1rem'
    },
    infoIcon: {
        verticalAlign: 'middle'
    },
    tooltip: {
        marginLeft: '4px',
        fill: "unset"
    },
    save: {
        margin: '8px 8px 0 0',
        color: 'white',
    }
});
const marks = [{ value: 1, label: 'Low' }, { value: 2, label: '' }, { value: 3, label: 'Medium' }, { value: 4, label: '' }, { value: 5, label: 'High' }]

function SetupForm({ onChange, config, loadConfig, onSaveConfig, onClickOptimize, onBatchChange,
    onClickAdvanceSettings, form, inputData, slates, resetCustomPointAndStack, ...rest }) {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState();
    const [isPosExposureOpen, setPosExposureOpen] = useState(false);
    const [isFlexExposureOpen, setFlexExposureOpen] = useState(false);
    const { sites, qbStacks, rbStacks, captainModeTitle, captainModeOptions } = inputData;
    const [isStackOpen, setStackOpen] = useState(false);
    const [isOwnershipOpen, setOwnershipOpen] = useState(false);
    const [isMembershipTierOpen, setIsMembershipTierOpen] = useState(false);
    const [membershipTierMsg, setMembershipTierMsg] = useState();
    const [isSaveConfigOpen, setSaveConfigOpen] = useState(false);
    const [isLoadConfigOpen, setLoadConfigOpen] = useState(false);
    const permissions = usePermissions();
    const {
        site, lineups,
        stackRB, stackQB,
        flexRB, flexWR, flexTE,
        gameFilter, diversity, isCaptainMode,
        randomize, randomizeOpts, posExposure, enablePosExposure,
        enableOwnershipSettings, ownership, enableCustomStacks,
        enableFlexExposure, flexExposure,
        enableTeamOwnership, enablePlayerOwnership, enablePlayerLimits,
        isAdvancedSettingsExpanded
    } = form;


    const toggleMembershipDialog = useCallback((msg) => {
        setIsMembershipTierOpen(open => !open);
        setMembershipTierMsg(typeof (msg) === 'string' ? msg : '');
    }, [setIsMembershipTierOpen, setMembershipTierMsg])

    const onInputChanged = React.useCallback((e, newValue) => {
        let { name, value, checked } = e.target;
        if (name === 'site' || name === 'gameFilter' || name === 'isCaptainMode') {
            resetCustomPointAndStack();
        }
        if (typeof (checked) === 'boolean' && (value === undefined || !value.length) && name !== 'lineups') {
            value = checked;
        } else if (typeof (newValue) === 'number') {
            name = 'diversity';
        }

        if (!permissions.advancedSettings && ADVANCED_FEATURES[name]) {
            value = false;
            toggleMembershipDialog(`${ADVANCED_FEATURES[name]} is a premium feature.`);
        }
        onChange({ name, value: value === undefined ? newValue : value });
    }, [onChange, resetCustomPointAndStack, toggleMembershipDialog, permissions]);

    const validateLineup = React.useCallback((event) => {
        let value = +event.target.value || 1; //number type value validation
        value = value < 1 ? 1 : value; //min value validation
        value = Number.isInteger(value) ? value : 1; //integer value validation
        let name = event.target.name;
        onChange({ name, value: value });
    }, [onChange]);

    const toggleAdvancedSettings = React.useCallback(() => {
        const name = "isAdvancedSettingsExpanded"
        const value = !isAdvancedSettingsExpanded
        onChange({ name, value: value });
    }, [onChange, isAdvancedSettingsExpanded]);

    const onFlexChanged = React.useCallback(e => {
        const { name, checked } = e.target;
        onChange({ name: ('flex' + name), value: checked });
    }, [onChange]);

    const toggleRandomizationDialog = useCallback(() => {
        setIsOpen(isOpen => !isOpen)
    }, [setIsOpen]);

    const togglePosExposureDialog = useCallback(() => {
        setPosExposureOpen(isPosExposureOpen => !isPosExposureOpen)
    }, [setPosExposureOpen]);

    const toggleFlexExposureDialog = useCallback(() => {
        setFlexExposureOpen(isFlexExposureOpen => !isFlexExposureOpen)
    }, [setFlexExposureOpen]);

    const toggleStackDialog = useCallback(() => {
        setStackOpen(open => !open);
    }, [setStackOpen]);

    const toggleOwnershipDialog = useCallback(() => {
        setOwnershipOpen(open => !open);
    }, [setOwnershipOpen]);


    const toggleSaveConfigDialog = useCallback(() => {
        setSaveConfigOpen(open => !open);
    }, [setSaveConfigOpen]);

    const toggleLoadConfigDialog = useCallback(() => {
        setLoadConfigOpen(open => !open);
    }, [setLoadConfigOpen]);



    const positionCount = useMemo(() => {
        let count = 0;
        count = flexRB ? count + 1 : count;
        count = flexWR ? count + 1 : count;
        count = flexTE ? count + 1 : count;
        return (count);
    }, [flexRB, flexWR, flexTE]);

    useEffect(() => {
        if (positionCount <= 1 && enableFlexExposure)
            onChange({ name: 'enableFlexExposure', value: false });
    }, [positionCount, enableFlexExposure, onChange]);

    return (
        <BaseCard title='Game Setup' className={classes.root}
            tooltip={`Every combination of Site and Captain mode will have their own unique list of saved configurations. In order to load a saved configuration ensure that you have the right Site and Captain Mode selected.`}
            action={<>
                <Tooltip title="Save Setup" placement="top">
                    <>
                    <Hidden mdDown>
                        <ColorButton
                            aria-label="save setup"
                            component="span"
                            size='small'
                            onClick={toggleSaveConfigDialog}
                            variant='contained'
                            startIcon={<Save />}
                            style={{ marginRight: '8px' }}
                        >
                            Save
                        </ColorButton>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            color="primary"
                            aria-label="save setup"
                            component="span"
                            className={classes.save}
                            size='small'
                            onClick={toggleSaveConfigDialog}
                        >
                            <Save fontSize='default' style={{ color: 'white' }} />
                        </IconButton>
                    </Hidden>
                    </>
                </Tooltip>
                <Tooltip title="Load Saved Setup" placement="top">
                    <>
                        <Hidden mdDown>
                            <ColorButton

                                aria-label="Load saved setup"
                                component="span"
                                size='small'
                                onClick={toggleLoadConfigDialog}
                                variant='contained'
                                startIcon={<Restore />}
                            >
                                Restore
                            </ColorButton>
                        </Hidden>
                        <Hidden mdUp>
                            <IconButton
                                color="primary"
                                aria-label="Load saved setup"
                                component="span"
                                className={classes.save}
                                size='small'
                                onClick={toggleLoadConfigDialog}
                            >
                                <Restore fontSize='default' style={{ color: 'white' }} />
                            </IconButton>
                        </Hidden>
                    </>
                </Tooltip>
            </>
            }>

            {enableCustomStacks &&

                <CustomStackDialog
                    isOpen={isStackOpen}
                    onToggle={toggleStackDialog}
                />
            }

            <SaveConfigDialog
                isOpen={isSaveConfigOpen}
                onCancel={toggleSaveConfigDialog}
                onSave={onSaveConfig}
                isLoading={config.isLoading}
                isError={config.isError}
                errorMessage={config.errorMessage}
            />

            <LoadConfigDialog
                isOpen={isLoadConfigOpen}
                onCancel={toggleLoadConfigDialog}
                configs={config.configs}
                loadConfig={loadConfig}
                isLoading={config.isLoading}
                isError={config.isError}
                errorMessage={config.errorMessage}
            />


            <MembershipTierDialog
                title="Premium Subscription Required"
                message={membershipTierMsg}
                isOpen={isMembershipTierOpen}
                onCancel={toggleMembershipDialog}

            />

            {randomize &&
                <RandomizerDialog
                    isOpen={isOpen}
                    onDone={onBatchChange}
                    onCancel={toggleRandomizationDialog}
                    initialValues={randomizeOpts}
                    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                    keepMounted={false}
                    title='Randomizer'
                    stateKey='randomizeOpts'
                    min={0}
                    max={30}
                />
            }
            {enablePosExposure &&
                <RandomizerDialog
                    isOpen={isPosExposureOpen}
                    onDone={onBatchChange}
                    onCancel={togglePosExposureDialog}
                    initialValues={posExposure}
                    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                    keepMounted={false}
                    title='Max Exposure By Position'
                    stateKey='posExposure'
                    min={10}
                    max={100}
                />
            }
            {
                enableOwnershipSettings &&
                <OwnershipSettingsDialog
                    isOpen={isOwnershipOpen}
                    onDone={onBatchChange}
                    onCancel={toggleOwnershipDialog}
                    initialValues={ownership}
                    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                    keepMounted={false}
                    stateKey='ownership'
                    onCheckboxClick={onInputChanged}
                    enableTeamOwnership={enableTeamOwnership}
                    enablePlayerOwnership={enablePlayerOwnership}
                    enablePlayerLimits={enablePlayerLimits}
                />
            }

            {enableFlexExposure && positionCount > 1 &&
                <FlexExposureDialog
                    isOpen={isFlexExposureOpen}
                    onDone={onBatchChange}
                    onCancel={toggleFlexExposureDialog}
                    initialValues={flexExposure}
                    TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
                    keepMounted={false}
                    title='Max Flex Exposure'
                    stateKey='flexExposure'
                    min={positionCount === 2 ? 50 : positionCount === 3 ? 33.34 : 0}
                    max={100}
                    flexRB={flexRB}
                    flexTE={flexTE}
                    flexWR={flexWR}
                />
            }

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>

                    <TextField
                        name='site'
                        id="select-site"
                        select
                        label="Site"
                        value={site}
                        onChange={onInputChanged}
                        variant="outlined"
                        size='small'
                        fullWidth={true}
                    >
                        {sites.map((site) => <MenuItem key={site.value} value={site.value}>{site.label}</MenuItem>)}
                    </TextField>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        name='lineups'
                        label="Lineup Count"
                        id="input-lineup"
                        variant="outlined"
                        fullWidth={true}
                        value={lineups}
                        inputProps={{ maxLength: 3, max: permissions?.maxLineups }}
                        size='small'
                        onChange={onInputChanged}
                        onBlur={validateLineup}
                    />
                </Grid>

                {/* QB Stack */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        name='stackQB'
                        select
                        label="QB Basic Stack"
                        value={stackQB}
                        onChange={onInputChanged}
                        variant="outlined"
                        size='small'
                        fullWidth={true}
                        disabled={isCaptainMode}
                    >
                        {qbStacks.map(stack => <MenuItem key={stack.label} value={stack.value}>{stack.label}</MenuItem>)}
                    </TextField>
                </Grid>

                {/* RB Stack */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        name='stackRB'
                        select
                        label="RB Basic Stack"
                        value={stackRB}
                        onChange={onInputChanged}
                        variant="outlined"
                        size='small'
                        fullWidth={true}
                        disabled={isCaptainMode}
                    >
                        {rbStacks.map(stack => <MenuItem key={stack.label} value={stack.value}>{stack.label}</MenuItem>)}
                    </TextField>
                </Grid>


                {/* Captain Mode */}

                <Grid item xs={12} sm={6} md={12} lg={6}>
                    <TextField
                        name='isCaptainMode'
                        select
                        value={isCaptainMode}
                        onChange={onInputChanged}
                        variant="outlined"
                        fullWidth
                        size='small'
                        label={captainModeTitle[site]}
                    >
                        {captainModeOptions.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {/* Game time */}

                <Grid item xs={12} sm={6} md={12} lg={6}>
                    <TextField
                        name='gameFilter'
                        select
                        value={gameFilter}
                        onChange={onInputChanged}
                        variant="outlined"
                        fullWidth
                        size='small'
                        label='Slate'
                    >
                        {slates.map(slate => (
                            <MenuItem key={slate} value={slate}>
                                {slate}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>


                <Grid container item xs={12} sm={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={6}>
                        <FormControl component="fieldset" disabled={isCaptainMode} >
                            <FormLabel component="legend" className={classes.checkboxLabel}>
                                Flex
                            </FormLabel>
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={flexRB} classes={{ root: classes.checkbox }} onClick={onFlexChanged} name="RB" />}
                                    label='RB'
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={flexWR} classes={{ root: classes.checkbox }} onClick={onFlexChanged} name="WR" />}
                                    label='WR'
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    control={<Checkbox color="primary" checked={flexTE} classes={{ root: classes.checkbox }} onClick={onFlexChanged} name="TE" />}
                                    label='TE'
                                    labelPlacement="end"
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>

                </Grid>
                <Accordion
                    expanded={isAdvancedSettingsExpanded}
                    onChange={toggleAdvancedSettings}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        style={{ padding: "unset", minHeight: "unset" }}
                        aria-controls="panel1d-content" id="panel1d-header"
                    >
                        <Typography variant="button" classes={{ root: classes.accordionTitle }}>Advanced Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingLeft: "0", paddingRight: "0" }}>
                        <Grid container item xs={12}>

                            <Grid item xs={7}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableCustomStacks}
                                            onChange={onInputChanged}
                                            name="enableCustomStacks"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement='end'
                                    label={
                                        <span>
                                            Custom Stacks
                                            <Tooltip className={classes.tooltip} title={<span>Beta feature - Create UP TO 25 stacks of players that are grouped together or excluded in user set percentage of the lineups. {permissions?.advancedSettings ? null : "( Premium Feature )"}</span>}>
                                                <Info fontSize='small' className={classes.infoIcon} color='inherit' />
                                            </Tooltip>
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FantasyButton
                                    fullWidth
                                    variant='outlined'
                                    disabled={!enableCustomStacks}
                                    onClick={toggleStackDialog}
                                    startIcon={<SettingsInputComponentOutlined />}
                                    label='Modify' />
                            </Grid>


                            <Grid item xs={7}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={randomize}
                                            onChange={onInputChanged}
                                            name="randomize"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement='end'
                                    label={
                                        <span >
                                            Randomize Projections
                                            <Tooltip className={classes.tooltip} title={<span>Beta feature - Add a bit of randomness to the projections to give you more unique lineups. Increase randomness to increase the uniqueness of the lineups. {permissions?.advancedSettings ? null : "( Premium Feature )"}</span>}>
                                                <Info fontSize='small' className={classes.infoIcon} color='inherit' />
                                            </Tooltip>
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FantasyButton
                                    fullWidth
                                    disabled={!randomize}
                                    variant='outlined'
                                    onClick={toggleRandomizationDialog}
                                    startIcon={<SettingsInputComponentOutlined />}
                                    label='Modify' />
                            </Grid>


                            <Grid item xs={7}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enablePosExposure}
                                            onChange={onInputChanged}
                                            name="enablePosExposure"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement='end'
                                    label={
                                        <span>
                                            Max Exposure By <br /> Position
                                            <Tooltip className={classes.tooltip} title={<span>Beta feature - Set a limit, by position, of how often any player can be in the number of lineups you chose. Note: This disables the Diversity settings below. {permissions?.advancedSettings ? null : "( Premium Feature )"}</span>}>
                                                <Info fontSize='small' className={classes.infoIcon} color='inherit' />
                                            </Tooltip>
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FantasyButton
                                    fullWidth
                                    disabled={!enablePosExposure}
                                    variant='outlined'
                                    onClick={togglePosExposureDialog}
                                    startIcon={<SettingsInputComponentOutlined />}
                                    label='Modify' />
                            </Grid>
                            <Grid item xs={7}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableOwnershipSettings}
                                            onChange={onInputChanged}
                                            name='enableOwnershipSettings'
                                            color="primary"
                                        />
                                    }
                                    labelPlacement='end'
                                    label={
                                        <span>
                                            Ownership Settings
                                            <Tooltip className={classes.tooltip} title={<span>Beta feature - Limit the amount of "highly owned" players you have in your lineup. {permissions?.advancedSettings ? null : "( Premium Feature )"}</span>}>
                                                <Info fontSize='small' className={classes.infoIcon} color='inherit' />
                                            </Tooltip>
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <FantasyButton
                                    fullWidth
                                    disabled={!enableOwnershipSettings}
                                    variant='outlined'
                                    onClick={toggleOwnershipDialog}
                                    startIcon={<SettingsInputComponentOutlined />}
                                    label='Modify' />
                            </Grid>

                            {!isCaptainMode && <Grid item xs={7}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={enableFlexExposure}
                                            disabled={positionCount <= 1}
                                            onChange={onInputChanged}
                                            name="enableFlexExposure"
                                            color="primary"
                                        />
                                    }
                                    labelPlacement='end'
                                    label={
                                        <span>
                                            Max Flex Exposure
                                            <Tooltip className={classes.tooltip} title={<span>Beta feature - Limit the frequency of each position in the flex spot. {permissions?.advancedSettings ? null : "( Premium Feature )"}</span>}>
                                                <Info fontSize='small' className={classes.infoIcon} color='inherit' />
                                            </Tooltip>
                                        </span>
                                    }
                                />
                            </Grid>}
                            {!isCaptainMode && <Grid item xs={5}>
                                <FantasyButton
                                    fullWidth
                                    disabled={!enableFlexExposure || positionCount <= 1}
                                    variant='outlined'
                                    onClick={toggleFlexExposureDialog}
                                    startIcon={<SettingsInputComponentOutlined />}
                                    label='Modify' />
                            </Grid>}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Grid item xs={12}>
                    <span>
                        <FormLabel className={classes.checkboxLabel} id="discrete-slider-restrict"> Diversity </FormLabel>
                        <Tooltip title={`Lineup Diversity only impacts your picks when you generate more than one lineup. Higher Lineup Diversity limits the number of lineups all players will be in, leading to more variance in your lineups, but usually lower average projected scores for some lineups. Lower Lineup Diversity allows top players to be in a greater percentage of lineups, leading to less variance in your lineups, but usually higher average projected scores. Note you can select how often you want individual players to show up in lineups on the "Player Settings" Page`}>
                            <Info fontSize='small' className={classes.infoIcon} color='inherit' />
                        </Tooltip>
                    </span>
                    <Slider defaultValue={1}
                        name='diversity'
                        disabled={enablePosExposure}
                        aria-labelledby="discrete-slider-restrict"
                        step={1}
                        min={1}
                        max={5}
                        value={diversity}
                        valueLabelDisplay="auto"
                        draggable={true}
                        marks={marks}
                        style={{ width: '95%', marginLeft: '2.5%' }}
                        color="secondary"
                        onChangeCommitted={onInputChanged} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FantasyButton label='Player Settings' variant='outlined' onClick={onClickAdvanceSettings} fullWidth />

                </Grid>
                <Grid item xs={12} sm={6}>
                    <FantasyButton label='Optimize' variant='contained' onClick={onClickOptimize} fullWidth />
                </Grid>
            </Grid>
        </BaseCard>
    )
}

SetupForm.propTypes = {
    config: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onSaveConfig: PropTypes.func.isRequired,
    onBatchChange: PropTypes.func.isRequired,
    onClickAdvanceSettings: PropTypes.func.isRequired,
    onClickOptimize: PropTypes.func.isRequired,
    form: PropTypes.shape({
        site: PropTypes.string.isRequired,
        lineups: PropTypes.number.isRequired,
        stackQB: PropTypes.oneOf([false, 'RB', 'TE']),
        stackRB: PropTypes.oneOf([false, 'DST']),
        flexWR: PropTypes.bool.isRequired,
        flexRB: PropTypes.bool.isRequired,
        flexTE: PropTypes.bool.isRequired,
        gameFilter: PropTypes.string.isRequired,
        diversity: PropTypes.number.isRequired
    }),
    slates: PropTypes.arrayOf(PropTypes.string),
    inputData: PropTypes.shape({
        site: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })),
        qbStacks: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
            label: PropTypes.string.isRequired,
        })),
        rbStacks: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
            label: PropTypes.string.isRequired,
        })),
        diversity: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
        })),
        gameFilters: PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }))

    })

}


export default React.memo(SetupForm, (prev, next) => {
    return prev.form.site === next.form.site &&
        prev.form.lineups === next.form.lineups &&
        prev.form.stackQB === next.form.stackQB &&
        prev.form.stackRB === next.form.stackRB &&
        prev.form.flexTE === next.form.flexTE &&
        prev.form.flexRB === next.form.flexRB &&
        prev.form.flexWR === next.form.flexWR &&
        prev.form.diversity === next.form.diversity &&
        prev.form.gameFilter === next.form.gameFilter &&
        prev.onClickOptimize === next.onClickOptimize &&
        JSON.stringify(prev.config) === JSON.stringify(next.config);
});