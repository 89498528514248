import types from "../actions/action-types";


const DEFAULT_STATE ={
  isError: false,
  isLoading: false,
  isSaving: false,
  config: {},
  configs:[],
  isSuccess: false,
  errorMessage: '',
  saveTS: 0
};
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case `${types.CONFIG_GET}_LOADING`:
      return {
        ...state,
        isError: false,
        isLoading: true,
        config: {},
      };
    case `${types.CONFIG_GET}_REJECTED`:
      return {
        ...state,
        isLoading:false,
        errorMessage: action.updatePayload.res,
        isError: true
      };
    case `${types.CONFIG_GET}_SUCCESS`:
      const config = action.updatePayload.res;
      return {
        ...state,
        isLoading:false,
        config,
        isError:false
      };
    case `${types.CONFIG_SAVE}_LOADING`:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
        isError: false,
      };
    case `${types.CONFIG_SAVE}_REJECTED`:
      return {
        ...state,
        isLoading:false,
        isSuccess: false,
        isError: true,
        errorMessage: action.updatePayload.res
      };
    case `${types.CONFIG_SAVE}_SUCCESS`:
      return {
        ...state,
        isLoading:false,
        isSuccess: true,
        isError:false,
        saveTS: new Date().getTime()
      };
    case `${types.CONFIG_GET_ALL}_SUCCESS`:
      const configs = action.updatePayload.res;
      return {
        ...state,
        configs: configs,
        isLoading:false,
        isSuccess: true,
        isError:false
      };
    case `${types.OPTIMIZE}_SUCCESS`:
      return {
        ...state,
        isLoading:false,
        isError:false,
        saveTS: new Date().getTime()
      };

    default:
      return state;
  }
};
