export default {
  // Auth based actions
  LOGIN: "LOGIN",

  // Get all matches
  GAMES: 'GAMES',
  PLAYERS: 'PLAYERS',
  OPTIMIZE: 'OPTIMIZE',
  FORM: 'FORM',
  SLATES: 'SLATES',
  CONFIG_GET: 'SETUP_CONFIG_GET',
  CONFIG_SAVE: 'SETUP_CONFIG_SAVE',
  CONFIG_GET_ALL: 'SETUP_CONFIG_GET_ALL',
};
