import types from "../actions/action-types";
const DEFAULT_STATE = {
  site: 'DraftKings',
  lineups: 1,
  isCaptainMode: false,
  stackQB: false,
  stackRB: false,
  flexTE: true,
  flexRB: true,
  flexWR: true,
  diversity: 3,
  gameFilter: '',
  excludeTeams:{},
  customPoints:{},
	exposure:{},
	lockExclude:{},
  ignoreExposureNotLocked: false,
  randomize: false,
  randomizeOpts: {
    GLOBAL:10,
    custom:false,
    QB:10,
    RB:10,
    DST:10,
    TE:10,
    WR:10
  },
  quickCustomScore: {},
  enablePosExposure: false,
  enableFlexExposure: false,
  flexExposure:{
    GLOBAL:100,
    TE:100,
    WR:100,
    RB:100,
    custom:false
  },
  posExposure: {
    GLOBAL: 100,
    QB    : 100,
    RB    : 100,
    WR    : 100,
    TE    : 100,
    DST   : 100
  },
  stacks: [],
  enableOwnershipSettings: false,
  ownership: {
    maxTeamOwnership: 500,
    excludePlayersAbove: 75,
    playerCount: 5,
    playerThreshold: 50,
  },
  enableCustomStacks: false,
  enableTeamOwnership: false,
  enablePlayerOwnership: false,
  enablePlayerLimits: false,
  isAdvancedSettingsExpanded: true
};

/**
 * Gets the main slate if it exists else the first element in the slate array.
 * @param {string[]} slates
 */
function getSlate(slates = []){
  if(slates.length === 0) return '';
  if(slates.findIndex(slate => slate === 'Main') >= 0) return 'Main';
  return slates[0];
}

export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case `${types.CONFIG_SAVE}_SUCCESS`:
      return {
        ...state,
        id: action.updatePayload.res.id
      }
    case `${types.CONFIG_GET}_SUCCESS`:
      const config = action.updatePayload.res;
      if(config.id === state.id) return state;
      delete config.gameFilter;
      return {
        ...state,
        ...config
      }
    case `${types.SLATES}_SUCCESS`: {
      return {
        ...state,
        gameFilter             : getSlate(action.updatePayload.res),
        ignoreExposureNotLocked: false,
        lockExclude            : {},
        exposure               : {},
        excludeTeams           : {}
      }
    }
    case `${types.FORM}_LOCK_EXCLUDE`:{
      const {value, key} = action.updatePayload;
      const newstate = {
        ...state,
        lockExclude: {
          ...state.lockExclude,
          [key]: value
        }
      }
      if(value !== 0){
        delete newstate.exposure[key];
        newstate.exposure = {...newstate.exposure};
      }
      return newstate
    }
    case `${types.FORM}_EXPOSURE`:{
      const {name,value, key} = action.updatePayload;
      let numValue = value ? Math.min(+value,100):value;

      return {
        ...state,
        exposure: {
          ...state.exposure,
          [key]: { ...state.exposure[key], [name]:numValue?numValue:''}
        },
        error: {form:[], exposure:false}
      }
    }
    case `${types.FORM}_UPDATE`:{
      let {name,value} = action.updatePayload;
      value = (name === 'stackQB' || name === 'stackRB') && value === 'false' ? false:value;
      return {
        ...state,
        [name]:value,
        error: {form:[], exposure:false},
        stacks: name === 'enableCustomStacks' && !value ? []:state.stacks
      };
    }
    case `${types.FORM}_UPDATE_BATCH`:{
      return {
        ...state,
        ...action.updatePayload,
        error: {form:[], exposure:false}
      };
    }
    case `${types.FORM}_ERROR`:
      return {
        ...state,
        error: action.updatePayload
      }
    case `${types.FORM}_RESET_PLAYERS`:
      return {
        ...state,
        lockExclude:{},
        exposure:{},
        customPoints:{}
      }

    case `${types.FORM}_CLEAR`:
      return {
        ...DEFAULT_STATE
      };

      case `${types.FORM}_RESET_CUSTOM_POINTS_AND_STACK`:
      {
        return{
          ...state,
          customPoints:{},
          enableCustomStacks:false,
          stacks: []
        }
      }
       //case for updating custom point value
       case `${types.FORM}_UPDATE_CUSTOM`:
        {
          const {name,value, key} = action.updatePayload;
          const newValue = value || '';
          const result = {
            ...state,
            customPoints: {
              ...state.customPoints,
              [key]: newValue
            }
          }
          if(!newValue){
            delete result.customPoints[key];
          }
          return result;

        }
        case `${types.FORM}_UPDATE_QUICK_CUSTOM_SCORE`:
          {
            const {value, key} = action.updatePayload;
            let customScore = value + (state.quickCustomScore[key] || 0);
            customScore = Math.abs(customScore) > 3 ? 0: customScore;
            const result = {
              ...state,
              quickCustomScore: {
                ...state.quickCustomScore,
                [key]: customScore
              }
            }
            if(!customScore){
              delete result.quickCustomScore[key];
            }
            return result;
          }
        case `${types.FORM}_UPDATE_STACKS`:
          {
            const {value} = action.updatePayload;
            return {
              ...state,
              stacks: value
            }

          }
    default:
      return state;
  }
};
